<template>
  <div>
    <main class="main">
      404 not found
    </main>
  </div>

</template>
<script lang=ts>
  export default {
      name:"Page404",
      components:{ },
      data(){
          return {

          }
      }
  }
</script>
<style lang=scss module>

</style>
